import * as React from "react";
import { graphql, Link } from "gatsby";
import {
  GatsbyImage,
  getImage,
  getSrc,
  StaticImage,
} from "gatsby-plugin-image";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";
import HowItWorksRow from "../../components/howItWorksRow";
import { stringToSlug } from "../../utils/stringManipulation";
import StereaImage from "../../components/stereaImage";
import { ImgComparisonSlider } from "@img-comparison-slider/react";

export const query = graphql`
  type StrapiGalleryitemContenuto implements Node {
    imageFile: File
  }
  query($strapiId: Int) {
    strapiProcedure(strapiId: { eq: $strapiId }) {
      id
      nome
      tipologia
      descrizione
      contenuto
      parentCategory {
        Name
      }
      copertina {
        localFile {
          absolutePath
          childImageSharp {
            gatsbyImageData(
              quality: 90
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      icona {
        localFile {
          absolutePath
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 90
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      intervento_padre {
        nome
      }
    }
    galleryItems: allStrapiGalleryitem(
      filter: { intervento: { id: { eq: $strapiId } } }
    ) {
      nodes {
        contenuto
        __typename
      }
    }
    parentProcedure: strapiProcedure(
      interventi_figli: { elemMatch: { id: { eq: $strapiId } } }
    ) {
      id
      nome
      interventi_figli {
        tipologia
      }
      intervento_padre {
        nome
      }
    }
    childrenProcedures: allStrapiProcedure(
      filter: { intervento_padre: { id: { eq: $strapiId } } }
    ) {
      nodes {
        id
        strapiId
        nome
        tipologia
        icona {
          localFile {
            absolutePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        copertina {
          localFile {
            absolutePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        descrizione
        interventi_figli {
          nome
          tipologia
        }
        intervento_padre {
          nome
        }
      }
    }
  }
`;

const SingleProcedure = ({ data, pageContext }) => {
  const renderLinkToProcedure = (procedure) => {
    if (procedure.interventi_figli.some((p) => p.tipologia === "pagina")) {
      return (
        <div className="sterea-procedure-card-wrapper">
          <div className="sterea-procedure-card">
            <h4 className="mb-6 font-bold text-center">{procedure.nome}</h4>
            {procedure.interventi_figli.map((p) => (
              <div className="mt-2">
                <Link
                  to={`/interventi/${stringToSlug(p.nome)}`}
                  className="hover:underline"
                >
                  {p.nome}
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <Link
          to={`/interventi/${stringToSlug(procedure.nome)}`}
          className="sterea-procedure-card-wrapper"
        >
          <div className="sterea-procedure-card">
            {procedure.icona?.localFile &&
              (procedure.icona?.localFile?.extension === "svg" ? (
                <img
                  src={procedure.icona?.localFile?.publicURL}
                  className="mb-6 h-32"
                  alt={`Icona che rappresenta un intervento di ${procedure.nome}`}
                />
              ) : (
                <GatsbyImage
                  image={getImage(procedure.icona?.localFile)}
                  className="h-96"
                  objectFit="cover"
                  alt={`Icona che rappresenta un intervento di ${procedure.nome}`}
                />
              ))}
            <h4 className="mt-6 font-bold text-center">{procedure.nome}</h4>
          </div>
        </Link>
      );
    }
  };

  const seoImage = (strapiProcedure) => {
    if (strapiProcedure.copertina) {
      return getSrc(strapiProcedure.copertina.localFile);
    } else if (strapiProcedure.icona) {
      return getSrc(strapiProcedure.icona.localFile);
    }

    return null;
  };

  return (
    <Layout>
      <SEO
        title={data.strapiProcedure.nome}
        description={data.strapiProcedure.descrizione?.replace(
          /<\/?[^>]+(>|$)/g,
          ""
        )}
        image={`https://stereamedicaltravel.it${seoImage(
          data.strapiProcedure
        )}`}
      />

      {/* {JSON.stringify(getSrc(data.strapiProcedure.icona?.localFile))}
      {seoImage(data.strapiProcedure)} */}

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        {data.strapiProcedure.copertina?.localFile ? (
          <>
            <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
            <GatsbyImage
              image={getImage(data.strapiProcedure?.copertina?.localFile)}
              style={{ position: "absolute" }}
              className="w-full h-full z-10"
              objectFit="cover"
              alt={`Immagine rappresentate un intervento di ${data.strapiProcedure.nome}`}
            />
            <div className="sterea-center-column h-3/4-screen z-20 flex justify-center items-center">
              <h1 className="w-11/12 text-white text-3xl lg:text-4xl leading-normal text-center font-bold">
                {data.strapiProcedure.nome}
              </h1>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="sterea-center-column h-1/4-screen z-20 flex justify-center items-center">
              <h1 className="w-11/12 text-sterea-blue text-center text-3xl lg:text-4xl leading-normal font-bold">
                {data.strapiProcedure.nome}
              </h1>
            </div>
          </>
        )}
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min lg:py-12">
          {data.strapiProcedure.descrizione && (
            <div className="flex flex-col lg:flex-row items-center">
              {data.strapiProcedure?.icona?.localFile && (
                <div className="w-full lg:w-5/12 flex justify-center">
                  {data.strapiProcedure?.icona?.localFile.extension ===
                  "svg" ? (
                    <img
                      src={data.strapiProcedure?.icona?.localFile?.publicURL}
                      className="mb-12 lg:mb-0 h-64"
                      alt={`Icona che rappresenta un intervento di ${data.strapiProcedure.nome}`}
                    />
                  ) : (
                    <GatsbyImage
                      image={getImage(data.strapiProcedure?.icona?.localFile)}
                      className="mb-12 lg:mb-0 h-64"
                      objectFit="cover"
                      alt={`Icona che rappresenta un intervento di ${data.strapiProcedure.nome}`}
                    />
                  )}
                </div>
              )}
              {data.strapiProcedure.descrizione && (
                <div
                  className={`sterea-content-container w-full ${
                    data.strapiProcedure?.icona?.localFile && "lg:w-7/12"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: data.strapiProcedure.descrizione,
                  }}
                ></div>
              )}
            </div>
          )}
          <div className="w-full flex flex-wrap justify-center">
            {data.childrenProcedures.nodes.map((intervento) =>
              renderLinkToProcedure(intervento)
            )}
          </div>
        </div>
      </div>

      {typeof window !== "undefined" && (
        <div className="sterea-center-column-wrapper">
          <div className="sterea-center-column">
            {data.galleryItems.nodes.length > 0 && (
              <>
                <Splide
                  options={{
                    pagination: false,
                    perPage: 1,
                    perMove: 1,
                    drag: false,
                    arrows:
                      data.galleryItems.nodes.filter(
                        (item) => item.contenuto[0] && item.contenuto[0].prima
                      ).length > 1,
                    // gap: "1rem",
                    // lazyLoad: "nearby",
                  }}
                  className="mb-12"
                >
                  {data.galleryItems.nodes.map(
                    (galleryItem) =>
                      galleryItem.contenuto[0] &&
                      galleryItem.contenuto[0].prima &&
                      galleryItem.contenuto[0].dopo && (
                        <SplideSlide className="flex justify-center items-center">
                          <ImgComparisonSlider>
                            <img
                              slot="before"
                              src={galleryItem.contenuto[0].prima.url}
                            />
                            <img
                              slot="after"
                              src={galleryItem.contenuto[0].dopo.url}
                            />
                          </ImgComparisonSlider>
                        </SplideSlide>
                      )
                  )}
                </Splide>
                <Splide
                  options={{
                    pagination: false,
                    perPage:
                      data.galleryItems.nodes.length < 3
                        ? data.galleryItems.nodes.length
                        : 3,
                    perMove: 1,
                    arrows:
                      data.galleryItems.nodes.filter(
                        (item) =>
                          item.contenuto[0] && item.contenuto[0].immagine
                      ).length > 3,
                    gap: "1rem",
                    lazyLoad: "nearby",
                  }}
                  className="px-24 hidden lg:block"
                >
                  {data.galleryItems.nodes.map(
                    (galleryItem) =>
                      galleryItem.contenuto[0] &&
                      galleryItem.contenuto[0].immagine && (
                        <SplideSlide className="h-64 flex justify-center items-center">
                          <StereaImage
                            images={galleryItem.contenuto[0].immagine}
                            sizes={"20vw"}
                            alt={
                              galleryItem.contenuto[0].immagine.alternativeText
                            }
                            className={
                              "max-w-full h-full max-h-full object-contain"
                            }
                          />
                        </SplideSlide>
                      )
                  )}
                </Splide>

                <Splide
                  options={{
                    pagination: false,
                    perPage: 1,
                    perMove: 1,
                    arrows:
                      data.galleryItems.nodes.filter(
                        (item) =>
                          item.contenuto[0] && item.contenuto[0].immagine
                      ).length > 1,
                    // gap: "1rem",
                    // lazyLoad: "nearby",
                  }}
                  className="lg:hidden"
                >
                  {data.galleryItems.nodes.map(
                    (galleryItem) =>
                      galleryItem.contenuto[0] &&
                      galleryItem.contenuto[0].immagine && (
                        <SplideSlide className="flex justify-center items-center">
                          <StereaImage
                            images={galleryItem.contenuto[0].immagine}
                            sizes={"70vw"}
                            alt={
                              galleryItem.contenuto[0].immagine.alternativeText
                            }
                          />
                        </SplideSlide>
                      )
                  )}
                </Splide>
              </>
            )}
          </div>
        </div>
      )}

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min mt-6 lg:mt-12">
          {data.strapiProcedure.contenuto && (
            <div
              className="sterea-content-container"
              dangerouslySetInnerHTML={{
                __html: data.strapiProcedure.contenuto,
              }}
            ></div>
          )}
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column py-12 px-min text-center">
          {/* If the parent procedure is only a folder of child procedures, we link to its parent instead of it */}
          {data.parentProcedure &&
          data.parentProcedure.interventi_figli.some(
            (p) => p.tipologia === "pagina"
          ) ? (
            <Link
              to={
                data.parentProcedure.intervento_padre
                  ? `/interventi/${stringToSlug(
                      data.parentProcedure.intervento_padre.nome
                    )}`
                  : `/interventi`
              }
              className="sterea-blue-purple-button"
            >
              {data.parentProcedure.intervento_padre?.nome ||
                "Tutti gli interventi"}
            </Link>
          ) : (
            <Link
              to={
                data.strapiProcedure.intervento_padre
                  ? `/interventi/${stringToSlug(
                      data.strapiProcedure.intervento_padre.nome
                    )}`
                  : `/interventi`
              }
              className="sterea-blue-purple-button"
            >
              {data.strapiProcedure.intervento_padre?.nome ||
                "Tutti gli interventi"}
            </Link>
          )}
        </div>
      </div>

      <HowItWorksRow category={data.strapiProcedure.parentCategory} />

      <NewsletterBanner />
    </Layout>
  );
};

export default SingleProcedure;
